import { inject } from 'vue';

const supportedLocalesKey = Symbol('supportedLocales');
const globalConfig = Symbol('globalConfig');
const honeypot = Symbol('honeypot');

export const useSupportedLocales = () => {
  return inject(supportedLocalesKey);
};
export const useHoneypot = () => inject(honeypot);

export const useSettings = (key) => {
  const config = inject(globalConfig);
  return config[key] || null;
};

const inlineHtml = (el) => {
  if (!el) {
    return;
  }

  // copy attributes to first child
  const content = el.tagName === 'TEMPLATE' ? el.content : el;
  if (content.children.length === 1) {
    [...el.attributes].forEach((attr) => content.firstChild.setAttribute(attr.name, attr.value));
  }

  // replace element with content
  if (el.tagName === 'TEMPLATE') {
    el.replaceWith(el.content);
  } else {
    el.replaceWith(...el.children);
  }
};

export const backendStorage = {
  install: (app, options) => {
    if (options != null && Object.keys(options).length > 0) {
      app.provide(supportedLocalesKey, options.supportedLocales || {});
      app.provide(globalConfig, options.settings);
    }
    app.directive('inline-html', inlineHtml);
    app.directive('inline-svg', inlineHtml);
  },
};
