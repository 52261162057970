<script setup>
import { onMounted, ref } from 'vue';
import { onClientMode } from '@/Plugins/ClientMode.js';
import { useEmitter } from '@/Plugins/Mitt.js';
import { EmitterEvents } from '@/Enums/EmitterEvents.js';
import ContactForm from '@/Components/ContactForm.vue';

const modalInstance = ref(),
  modal = ref(),
  emitter = useEmitter();

const onShowModal = () => modalInstance.value?.show();

onMounted(() => {
  emitter.on(EmitterEvents.CTA_MODAL_OPEN, onShowModal);

  onClientMode(() => {
    import('flowbite/lib/esm/components/modal').then((Module) => {
      modalInstance.value = new Module.default(modal.value);
    });
  });
});
</script>

<template>
  <Teleport to="body">
    <div
      ref="modal"
      tabindex="-1"
      aria-hidden="true"
      class="fixed left-0 right-0 top-0 z-50 hidden h-[calc(100%-1rem)] max-h-full w-full items-center justify-center overflow-y-auto overflow-x-hidden md:inset-0"
    >
      <div class="relative max-h-full w-full max-w-2xl md:p-4">
        <!-- Modal content -->
        <div class="cta relative shadow dark:bg-gray-700 md:rounded-xl">
          <!-- Modal header -->
          <div class="flex items-center justify-between rounded-t border-b p-4 dark:border-gray-600 md:p-5">
            <h3 class="text-xl font-semibold text-gray-900 text-white dark:text-white">{{ $t('Get in touch') }}</h3>
            <button
              type="button"
              class="hover:bg-gray-[#1a213e] end-2.5 ms-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-white hover:text-gray-50 hover:text-gray-900"
              @click="modalInstance.hide()"
            >
              <svg
                class="h-3 w-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <!-- Modal body -->
          <div class="p-4 md:p-5">
            <ContactForm
              two-columns
              @submitted="modalInstance.hide()"
            />
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style scoped></style>
