<script setup>
import Select from '@/Components/FormElements/Select.vue';
import CtaButton from '@/Components/CtaButton.vue';
import TextInput from '@/Components/FormElements/TextInput.vue';
import TextArea from '@/Components/FormElements/TextArea.vue';
import { useForm, usePage } from '@inertiajs/vue3';
import { ContactSubject } from '@/Enums/Enums.js';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

defineProps({
  twoColumns: {
    type: Boolean,
    default: false,
  },
});

const { honeypot } = usePage().props,
  { t, locale } = useI18n();

const form = useForm({
  full_name: null,
  locale: locale.value,
  email: null,
  subject: null,
  message: null,
  terms_accepted: true,
  ...(honeypot != null && honeypot.enabled
    ? {
        [honeypot.nameFieldName]: '',
        [honeypot.validFromFieldName]: honeypot.encryptedValidFrom,
      }
    : {}),
});

const subjects = ref(
  Object.values(ContactSubject).map((value) => ({
    value,
    text: t(value),
  })),
);

const emits = defineEmits(['submitted']);

const onSubmit = () =>
  form.post('/contact/', {
    preserveScroll: true,
    preserveState: true,
    onSuccess: () => {
      form.reset('full_name', 'email', 'subject', 'message', 'terms_accepted');
      emits('submitted');
    },
  });
</script>

<template>
  <form
    class="grid grid-cols-2 gap-6"
    @submit.prevent="onSubmit"
  >
    <div
      v-if="honeypot?.enabled"
      :name="`${honeypot.nameFieldName}_wrap`"
      style="display: none"
    >
      <input
        :id="honeypot.nameFieldName"
        v-model="form[honeypot.nameFieldName]"
        type="text"
        :name="honeypot.nameFieldName"
      />
      <input
        v-model="form[honeypot.validFromFieldName]"
        type="text"
        :name="honeypot.validFromFieldName"
      />
    </div>
    <TextInput
      v-model="form.full_name"
      :error="form.errors.full_name || null"
      class="col-span-2 sm:col-span-1 md:col-span-2"
      :class="{ 'lg:col-span-1': twoColumns }"
      :label="$t('Full name')"
      :placeholder="$t('Please enter Full name')"
    />
    <TextInput
      v-model="form.email"
      :error="form.errors.email || null"
      class="col-span-2 sm:col-span-1 md:col-span-2"
      :class="{ 'lg:col-span-1': twoColumns }"
      type="email"
      :label="$t('E-Mail')"
      :placeholder="$t('Please enter your email')"
    />
    <Select
      v-model="form.subject"
      :error="form.errors.subject || null"
      class="col-span-2"
      :options="subjects"
      :placeholder="$t('Please select')"
      :label="$t('How can we be in help?')"
    />
    <TextArea
      v-model="form.message"
      :error="form.errors.message || null"
      class="col-span-2"
      :label="$t('What project do you have in mind?')"
      :placeholder="$t('Please provide details of your project')"
    />
    <div class="col-span-2 text-sm text-white">
      {{
        $t(
          'By submitting this form, I agree that the information entered will be used in the context of the contract request and the business relationship that may result from it. To know and exercise your rights, including the withdrawal of your consent to the of the data collected by this form, please consult our privacy policy.',
        )
      }}
    </div>
    <div class="col-span-2 text-center">
      <CtaButton type="submit" />
    </div>
  </form>
</template>
