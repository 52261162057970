<script setup>
import FormElement from '@/Components/FormElements/FormElement.vue';
import { ref } from 'vue';

const value = defineModel('modelValue', { default: null, type: [String, Number] });
const el = ref(null);

defineProps({
  label: [String, null],
  placeholder: [String, null],
  rows: {
    type: Number,
    default: 3,
  },
  error: [String, null],
  disabled: Boolean,
  required: Boolean,
});
</script>

<template>
  <FormElement ref="el">
    <template
      v-if="label"
      #label
    >
      {{ label }}
    </template>
    <template
      v-if="error"
      #error
    >
      {{ error }}
    </template>
    <textarea
      :id="el?.id"
      v-model="value"
      :placeholder="placeholder"
      :rows="rows"
      :disabled="disabled"
      :class="{
        'bg-white': !error,
        'bg-red-200': error,
      }"
      class="block w-full rounded-xl border border-gray-300 px-5 py-3.5 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
    />
  </FormElement>
</template>

<style scoped></style>
