<script setup>
import { onMounted, ref } from 'vue';
import { Link } from '@inertiajs/vue3';
import { onClientMode } from '@/Plugins/ClientMode.js';
import { useSupportedLocales } from '@/Plugins/GlobalStorePlugin.js';

const supportedLocales = useSupportedLocales();
const dropdownInstance = ref(null),
  dropdownBtn = ref(null),
  dropdownContainer = ref(null);

onMounted(() => {
  onClientMode(() => {
    import('flowbite/lib/esm/components/dropdown').then((Module) => {
      dropdownInstance.value = new Module.default(dropdownContainer.value, dropdownBtn.value, {
        placement: 'bottom-end',
        triggerType: 'click',
      });
    });
  });
});
</script>
<script>
import enFlag from '@/Components/Flags/EnFlag.vue';
import frFlag from '@/Components/Flags/FrFlag.vue';
import nlFlag from '@/Components/Flags/NlFlag.vue';

export default {
  components: {
    enFlag,
    nlFlag,
    frFlag,
  },
};
</script>
<template>
  <div>
    <button
      ref="dropdownBtn"
      type="button"
      class="inline-flex items-center justify-between text-sm font-bold"
    >
      <component
        :is="`${$page.props.locale}Flag`"
        class="me-2 h-4"
      />
      {{ $page.props.locale.toUpperCase() }}
      <svg
        class="ms-2 h-2.5 w-2.5"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 10 6"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m1 1 4 4 4-4"
        />
      </svg>
    </button>

    <!-- Dropdown menu -->
    <div
      ref="dropdownContainer"
      class="hidden divide-y divide-gray-100 rounded-xl bg-white shadow"
    >
      <div class="py-2 text-sm text-gray-700">
        <template
          v-for="(locale, code) in supportedLocales"
          :key="`${code}Row`"
        >
          <Link
            :href="$page.props.localizedUrls[code]"
            :hreflang="code"
            :lang="code"
            rel="alternate"
            class="flex items-center px-4 py-2 text-lg text-[#3B3C3F] hover:bg-gray-100"
          >
            <component
              :is="`${code}Flag`"
              class="me-2 h-4"
            />
            {{ locale.native }}
            <svg
              v-if="code === $page.props.locale"
              class="ms-4 h-4"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.9336 4.06641C14.1797 4.33984 14.1797 4.75 13.9336 4.99609L6.71484 12.2148C6.44141 12.4883 6.03125 12.4883 5.78516 12.2148L2.06641 8.49609C1.79297 8.25 1.79297 7.83984 2.06641 7.59375C2.3125 7.32031 2.72266 7.32031 2.96875 7.59375L6.22266 10.8477L13.0039 4.06641C13.25 3.82031 13.6602 3.82031 13.9062 4.06641H13.9336Z"
                fill="#191817"
              />
            </svg>
          </Link>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
