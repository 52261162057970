<script setup>
import { defineAsyncComponent, shallowRef } from 'vue';

const blocks = import.meta.glob('./Blocks/*.vue');
const components = shallowRef({});

Object.entries(blocks).forEach(([path]) => {
  // Get name of component, based on filename
  // "./components/Fruits.vue" will become "Fruits"
  const componentName = path
    .split('/')
    .pop()
    .replace(/\.\w+$/, '');

  components.value[componentName] = defineAsyncComponent(() => import(`./Blocks/${componentName}.vue`));
});
</script>

<template>
  <template
    v-for="section in $page.props.sections"
    :key="section.id"
  >
    <section :class="section.classes">
      <template
        v-for="block in section.blocks"
        :key="block.id"
      >
        <component
          :is="components[block.name]"
          :id="block.id"
          v-bind="{ ...block.data, ...(block.settings || {}) }"
        />
      </template>
    </section>
  </template>
</template>
